















import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Empty' })
export class EmptyWishlist extends Vue {}
export default EmptyWishlist
