import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const WISHLIST_VIEW_KEY = 'WishlistView'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: AnyObject = {
  inProfile: false
}
